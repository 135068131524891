.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

#wtdd {
  position: relative;
  display: flex;
  flex: 1 1;
  user-select: none;
}

#mutedwarn {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  color: white;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
}

video {
  display: flex;
  flex: 1 1;
  user-select: none;
}